.rounded {
    border-radius: 16px;
}

.faux-link {
    text-decoration: underline;
    color: #2e2ec0;
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;
}

img.profile-avatar {
    border-radius: 50%;
}

#asdf{
    background: #c9bbee;
    color: rgb(139, 92, 246);
}

.display-linebreak {
    white-space: pre-line;
}

.clickable {
    cursor: pointer;
}

.clickable-not {
    cursor: not-allowed;
}

.credits {
    color: #ffc107;
}

html,
body {
    height: 100%;
    margin: 0;
}

#root {
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
}

.border-none {
    border-color: rgba(0, 0, 0, 0) !important;
}

.shadow-hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
}

.shadow-hover:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.loading-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.faded-white-bg {
    width: 100%;
    height: 100%;
    color: rgba(255, 255, 255, 0.99);
}

.input-group {
    box-shadow: none !important;
}
