.border-bottom {
    border-bottom: 2rem solid #6b6f72;
}

.email-editor .invisible-text {
    background-color: #f3f3f3;
    border-color: rgba(0, 0, 0, 0);
    resize: none;
}

.email-editor .invisible-text:focus {
    outline: none;
}